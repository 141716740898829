import { Box, Grid, ClickAwayListener, Fade } from "@mui/material";
import { webs } from "../nav_webs.json";
import { useMemo } from "react";

const styles = {
  padding: "3rem",
  paddingTop: "1rem",
};

const NavOthersWebs = ({ handleOnBlur, open }) => {
  const websOrdered = useMemo(() => {
    return Object.values(webs)
      .map((v) => v)
      .sort((a, b) => a.orderPriority - b.orderPriority);
  });

  return (
    <>
      <ClickAwayListener onClickAway={handleOnBlur}>
        <Fade in={open} timeout={500}>
          <Box sx={styles}>
            <Grid container spacing={5}>
              {websOrdered.map((web) => {
                return (
                  <Grid
                    item
                    xs={4}
                    md={3}
                    lg={2}
                    display="flex"
                    justifyContent="center"
                    sx={{
                      filter: "grayscale(100%)",
                      cursor: "pointer",
                      "&:hover": { filter: "grayscale(0)" },
                    }}
                  >
                    <a href={web.url} target="_blank" rel="noreferrer">
                      <img src={web.icon} alt="" width={150} height={60} />
                    </a>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Fade>
      </ClickAwayListener>
    </>
  );
};

export default NavOthersWebs;
